import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { UserProvider } from './contexts/UserContext';
import { ServicesProvider } from './contexts/ServicesContext';
import { TranslationsProvider } from './contexts/TranslationsContext';
import reportWebVitals from './reportWebVitals';
import { ThemeModeProvider } from "./contexts/ThemeModeProvider";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <AuthProvider>
            <UserProvider>
                <TranslationsProvider>
                    <ServicesProvider>
                        <ThemeModeProvider>
                            <App />
                        </ThemeModeProvider>
                    </ServicesProvider>
                </TranslationsProvider>
            </UserProvider>
        </AuthProvider>
    </BrowserRouter>
);

reportWebVitals();