import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, Alert, TextField, TableSortLabel
} from '@mui/material';

const TransactionManagement = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [transactions, setTransactions] = useState([]);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [sortBy, setSortBy] = useState('timestamp');
  const [sortOrder, setSortOrder] = useState('asc');
  const [search, setSearch] = useState('');

  const getToken = () => localStorage.getItem('token');

  const fetchTransactions = useCallback((search = '', sortBy = 'timestamp', sortOrder = 'asc') => {
    const token = getToken();
    axios.get(`${apiUrl}/admin/transactions/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: { search, sort_by: sortBy, sort_order: sortOrder }
    })
    .then(response => setTransactions(response.data.transactions))
    .catch(error => {
      console.error("Error fetching transactions:", error);
      setAlertMessage('Error fetching transactions');
      setAlertSeverity('error');
    });
  }, [apiUrl]);

  useEffect(() => {
    fetchTransactions(search, sortBy, sortOrder);
  }, [search, sortBy, sortOrder, fetchTransactions]);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    fetchTransactions(e.target.value, sortBy, sortOrder);
  };

  const handleSort = (column) => {
    const isAsc = sortBy === column && sortOrder === 'asc';
    const newSortOrder = isAsc ? 'desc' : 'asc';
    setSortOrder(newSortOrder);
    setSortBy(column);
    fetchTransactions(search, column, newSortOrder);
  };

  return (
    <div style={{ padding: '2rem' }}>
      <h1>Transaction Management Panel</h1>
      {alertMessage && <Alert style={{ marginBottom: '10px' }} severity={alertSeverity}>{alertMessage}</Alert>}
      <TextField
        label="Search"
        value={search}
        onChange={handleSearchChange}
        fullWidth
        style={{ marginBottom: '1rem' }}
      />
      <TableContainer component={Paper} style={{ marginTop: '1rem', width: '100%', overflowX: 'auto', maxHeight: '70vh' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortBy === 'user_email'}
                  direction={sortOrder}
                  onClick={() => handleSort('user_email')}
                >
                  User/Guest ID Email
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortBy === 'service_name'}
                  direction={sortOrder}
                  onClick={() => handleSort('service_name')}
                >
                  Service Name
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortBy === 'transaction_type'}
                  direction={sortOrder}
                  onClick={() => handleSort('transaction_type')}
                >
                  Transaction Type
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortBy === 'amount'}
                  direction={sortOrder}
                  onClick={() => handleSort('amount')}
                >
                  Amount
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortBy === 'timestamp'}
                  direction={sortOrder}
                  onClick={() => handleSort('timestamp')}
                >
                  Timestamp
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactions.map((transaction) => (
              <TableRow key={transaction.id}>
                <TableCell>{transaction.id}</TableCell>
                <TableCell>{transaction.user_email || transaction.guest_user_id || 'N/A'}</TableCell>
                <TableCell>{transaction.service_name || 'Token Adding Batch'}</TableCell>
                <TableCell>{transaction.transaction_type}</TableCell>
                <TableCell>{transaction.amount}</TableCell>
                <TableCell>{new Date(transaction.timestamp).toLocaleString()}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default TransactionManagement;