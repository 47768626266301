import React, { useContext } from 'react';
import { Box, Typography, Card, Button } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { ServicesContext } from '../contexts/ServicesContext';
import { useTranslations } from '../contexts/TranslationsContext';
import SwiperSlideshow from './SwiperSlideshow';
import SignupButton from './HeaderComponents/SignupButton';
import LaunchIcon from '@mui/icons-material/Launch';
import ToolIcon from './ToolIcon';
import TopPageSection from './TopPageSection';

const ServiceCard = styled(Card)(({ theme }) => ({
  width: '300px',
  height: '350px', // Increase height for larger icon section
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center', // Center content horizontally
  justifyContent: 'center', // Center content vertically
  gap: theme.spacing(2),
  padding: theme.spacing(2, 2),
}));

const ServiceTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.5rem', // 24px for desktop
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.25rem', // 20px for mobile
  },
  fontWeight: 'bold',
  color: theme.palette.text.main,
}));

const ServiceDescription = styled(Typography)(({ theme }) => ({
  fontSize: '1rem', // 16px for desktop
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.875rem', // 14px for mobile
  },
  color: theme.palette.text.secondary,
}));

const SignupBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(2),
}));

const AITools = ({ language }) => {
  const { services } = useContext(ServicesContext);
  const { translations } = useTranslations();
  const navigate = useNavigate();

  const handleNavigate = (url) => {
    navigate(url);
  };

  const slides = services.map((service, index) => (
    <ServiceCard key={index} variant="gradient">
      <ToolIcon serviceType={service.service_type} />
      <ServiceTitle variant="h2">
        {service.title[language]}
      </ServiceTitle>
      <ServiceDescription>
        {service.description[language]}
      </ServiceDescription>
      <Button
        variant="gradient"
        onClick={() => handleNavigate(service.url)}
        endIcon={<LaunchIcon />}
      >
        {translations.learnMore}
      </Button>
    </ServiceCard>
  ));

  return (
    <TopPageSection 
      title={translations.servicesTitle} 
      description={translations.servicesDescription}
    >
      <SwiperSlideshow slides={slides} />
      <SignupBox>
        <Typography
          variant="body1"
          sx={{
            color: "text.secondary",
            fontSize: { xs: "0.9rem", sm: "1rem" },
          }}
        >
          {translations.tryAIModels}
        </Typography>
        <SignupButton translations={translations}/>
      </SignupBox>
    </TopPageSection>
  );
};

export default AITools;