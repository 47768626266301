import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import { Home, BuildCircle, Translate, MonetizationOn, People, GroupAdd } from '@mui/icons-material';
import {
    HeaderDesktopNav,
    HeaderNavItem,
} from '../HeaderComponents/HeaderStyles';

const DesktopNav = ({ isAuthenticated, user, translations, handleLogout, handlePopoverOpen, serviceButton, language, setLanguage }) => {
    const navigate = useNavigate();

    return (
        <>
            {/* Desktop Navigation */}
            <HeaderDesktopNav sx={{ display: { md: 'flex' }}}>
                <HeaderNavItem>
                    <IconButton onClick={() => navigate(`/admin_panel/`)}>
                        <Home />
                    </IconButton>
                    <IconButton onClick={() => navigate(`/admin_panel/service-management`)}>
                        <BuildCircle />
                    </IconButton>
                    <IconButton onClick={() => navigate(`/admin_panel/translation-management`)}>
                        <Translate />
                    </IconButton>
                    <IconButton onClick={() => navigate(`/admin_panel/transaction-management`)}>
                        <MonetizationOn />
                    </IconButton>
                    <IconButton onClick={() => navigate(`/admin_panel/user-management`)}>
                        <People />
                    </IconButton>
                    <IconButton onClick={() => navigate(`/admin_panel/guest-user-management`)}>
                        <GroupAdd />
                    </IconButton>
                </HeaderNavItem>
            </HeaderDesktopNav>
        </>
    );
};

export default DesktopNav;