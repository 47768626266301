import React from 'react';
import AboutUs from './AboutUs';
import AITools from './AITools';
import ContactSection from "./ContactSection";
import { Box } from '@mui/material';
import { styled } from '@mui/system';

const TopPageContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(1),
}));

const TopPage = ({ language }) => {
  return (
    <TopPageContainer>
      <AboutUs /> 
      
      <AITools language={language} />

      <ContactSection /> 
    </TopPageContainer >
  );
};

export default TopPage;