import React, { createContext, useState, useEffect, useCallback, useRef } from 'react';
import { jwtDecode } from 'jwt-decode';
import { getFingerprint } from '../utils/fingerprint';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const apiUrl = process.env.REACT_APP_API_URL;

    const [token, setToken] = useState(localStorage.getItem('token'));
    const [guestToken, setGuestToken] = useState(localStorage.getItem('guestToken'));
    const [isAuthenticated, setIsAuthenticated] = useState(!!token);
    const [language, setLanguage] = useState('jp');
    const [theme, setTheme] = useState('dark');
    const initialVerificationDone = useRef(false);
    const [role, setRole] = useState('guest');
    const [loading, setLoading] = useState(true);


    const initializeGuestSession = useCallback(async () => {
        setLoading(true);
        const fingerprint = await getFingerprint();
        try {
            const response = await fetch(`${apiUrl}/login/guest`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ fingerprint }),
            });

            if (response.ok) {
                const data = await response.json();
                const decodedToken = jwtDecode(data.access_token);
                localStorage.setItem('guestToken', data.access_token);
                setGuestToken(data.access_token);
                setLanguage(decodedToken.language);
                setTheme(decodedToken.theme);
            } else {
                console.error('Failed to initialize guest session');
            }
        } catch (error) {
            console.error('Error initializing guest session:', error);
        } finally {
            setLoading(false);
        }
    }, [apiUrl]);

    const verifyGuestToken = useCallback(async () => {
        if (!guestToken) {
            await initializeGuestSession();
            return;
        }

        try {
            const response = await fetch(`${apiUrl}/verify/guest_token`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${guestToken}`,
                },
            });
            if (response.ok) {
                const data = await response.json();
                const decodedToken = jwtDecode(data.access_token);
                localStorage.setItem('guestToken', data.access_token);
                setGuestToken(data.access_token);
                setLanguage(decodedToken.language);
                setTheme(decodedToken.theme);
            }
            else {
                localStorage.removeItem('guestToken');
                setGuestToken(null);
                await initializeGuestSession();
            }
        } catch (error) {
            console.error('Error verifying guest token:', error);
            await initializeGuestSession();
        } finally {
            setLoading(false);
        }
    }, [apiUrl, guestToken, initializeGuestSession]);

    const isTokenExpired = (token) => {
        try {
            const decodedToken = jwtDecode(token);
            const currentTime = Date.now() / 1000;
            return decodedToken.exp < currentTime;
        } catch (error) {
            console.error('Error decoding token:', error);
            return true;
        }
    };

    useEffect(() => {
        const verifyToken = async () => {
            if (token && !isTokenExpired(token)) {
                try {
                    const response = await fetch(`${apiUrl}/verify/token`, {
                        method: 'POST',
                        headers: {
                            'Authorization': `Bearer ${token}`,
                        },
                    });

                    if (response.ok) {
                        const data = await response.json();
                        const decodedToken = jwtDecode(data.access_token);
                        localStorage.setItem('token', data.access_token);
                        setRole(decodedToken.role);
                        setIsAuthenticated(true);
                        setLanguage(decodedToken.language);
                        setTheme(decodedToken.theme);
                    } else {
                        setIsAuthenticated(false);
                        setRole('guest');
                        localStorage.removeItem('token');
                        setToken(null);
                        await initializeGuestSession();
                    }
                } catch (error) {
                    setIsAuthenticated(false);
                    setRole('guest');
                    localStorage.removeItem('token');
                    setToken(null);
                    await initializeGuestSession();
                }
            } else {
                localStorage.removeItem('token');
                setToken(null);
                await verifyGuestToken();
            }
            setLoading(false);
            initialVerificationDone.current = true;
        };

        if (!initialVerificationDone.current) {
            verifyToken();
        }
    }, [token, apiUrl, verifyGuestToken, initializeGuestSession]);

    const login = (newToken) => {
        const decodedToken = jwtDecode(newToken);
        setToken(newToken);
        setIsAuthenticated(true);
        setRole(decodedToken.role);
        setLanguage(decodedToken.language);
        setTheme(decodedToken.theme);
        localStorage.setItem('token', newToken);
        localStorage.removeItem('guestToken');
        setGuestToken(null);
    };

    const logout = () => {
        setToken(null);
        setIsAuthenticated(false);
        setRole('guest');
        localStorage.removeItem('token');
        localStorage.removeItem('guestToken');
        setGuestToken(null);
        initializeGuestSession();
    };

    return (
        <AuthContext.Provider value={{ token, guestToken, role, isAuthenticated, initializeGuestSession, login, logout, language, setLanguage, theme, setTheme, loading }}>
            {children}
        </AuthContext.Provider>
    );
};