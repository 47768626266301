import React, { createContext, useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from './AuthContext';

const getToken = (isAuthenticated) => isAuthenticated ? 
  localStorage.getItem('token') : 
  localStorage.getItem('guestToken')

// Create ServicesContext
export const ServicesContext = createContext();
const apiUrl = process.env.REACT_APP_API_URL;

export const ServicesProvider = ({ children }) => {
    const [AIService, setAIService] = useState([]);
    const [services, setServices] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const { isAuthenticated, loading: authLoading } = useContext(AuthContext);

    useEffect(() => {
        if (!authLoading) {
            const fetchServices = async () => {
                setLoading(true);
                try {
                    const response = await axios.get(`${apiUrl}/data/services`, {
                        headers: { 'Authorization': `Bearer ${getToken(isAuthenticated)}` }
                    });
                    const data = response.data;

                    setAIService(data.services);
                    setServices(data.services);
                    setLoading(false); // Set loading to false only if fetching is successful
                } catch (error) {
                    console.error('Error fetching services:', error);
                    setLoading(true); // Keep loading true if there's an error
                }
            };

            fetchServices();
        }
    }, [isAuthenticated, authLoading]);

    return (
        <ServicesContext.Provider value={{ AIService, services, loading, navigate }}>
            {children}
        </ServicesContext.Provider>
    );
};

export const useServices = () => {
    return useContext(ServicesContext);
};